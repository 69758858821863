<template>
  <div class="main">
    <div class="card" @click="navigateTo('toolDetail')">
      <div class="head flex-between-center" :class="theme">
        <div class="title">
          {{ tool.name }}
        </div>
        <div class="tags flex-start-center">
          <tag :name="transferDict('tool_status', tool.tool_status)"></tag>
        </div>
      </div>
      <div class="info">
        <div class="flex-start-center">
          <div class="label">所属大区：</div>
          <div class="value">{{ tool.region_name }}</div>
        </div>
        <div class="flex-start-center">
          <div class="label">工具归属者：</div>
          <div class="value">{{ tool.owner_name }}</div>
        </div>
        <div class="flex-start-center" v-if="tool?.use_start_time && tool?.use_end_time">
          <div class="label">使用时间：</div>
          <div class="value">
            {{ new Date(tool?.use_start_time).toLocaleDateString() }}～{{ new Date(tool?.use_end_time).toLocaleDateString() }}
          </div>
        </div>
        <div class="flex-start-center">
          <div class="label">当前使用者：</div>
          <div class="value">{{ tool.tool_user_name }}</div>
        </div>
        <div class="flex-start-center">
          <div class="label">预约借调者：</div>
          <div class="value">{{ tool.second_user_name || '无' }}</div>
        </div>
      </div>
    </div>
    <div class="operate">
      <tl-button
        name="使用"
        type="blue"
        v-if="
          this.tool.tool_user == user.user_id &&
          this.tool.owner == user.user_id &&
          (this.tool.tool_status == 1 || this.tool.tool_status == 9)
        "
        @click="useTool"
      />
      <tl-button
        name="使用完成"
        type="blue"
        v-if="this.tool.tool_user == user.user_id && this.tool.tool_status == 3"
        @click="completedUseTool"
      />
      <tl-button
        name="校验"
        type="white"
        v-if="this.tool.tool_user == user.user_id && this.tool.tool_status !== 5 && this.tool.is_check === 1"
        @click="verfiyTool"
      />
      <tl-button name="维修" type="black" v-if="this.tool.tool_user == user.user_id && this.tool.tool_status !== 5" @click="repairTool" />
    </div>
  </div>
</template>

<script>
import ToolApi from '@/utils/api/tool';
import ProcessApi from '@/utils/api/process';
import { transferDict } from '@/utils/utils';
import { useUserStore } from '@/stores/useUserStore';
export default {
  name: 'TlCard',
  props: {
    dicts: Array,
    tool: Object,
  },
  data() {
    return {
      theme: '',
      user: {},
    };
  },
  created() {
    const userStore = useUserStore();
    this.user = userStore.user;
  },
  mounted() {
    this.theme = `status${this.tool.tool_status}`;
  },
  methods: {
    transferDict(dictName, key) {
      return key && transferDict(this.dicts, dictName, key);
    },
    async change() {
      console.log(this.form);
    },

    navigateTo(name) {
      this.$router.push({ name, query: { tool_id: this.tool.tool_id } });
    },

    verfiyTool() {
      this.$confirm('确认校验工具吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          // 用户点击确定执行的逻辑
          ProcessApi.addToolCheckFlow(this.tool.tool_id)
            .then((res) => {
              if (res.status) {
                this.$message({
                  message: '提交成功',
                  type: 'success',
                });
                this.$parent.getList();
              } else {
                this.$message({
                  message: res.message || '提交失败',
                  type: 'error',
                });
              }
            })
            .catch((err) => {
              this.$message({
                message: err.message || '提交失败',
                type: 'error',
              });
            });
        })
        .catch(() => {
          // 用户点击取消执行的逻辑
          this.$message({
            type: 'info',
            message: '已取消操作',
          });
        });
    },
    useTool() {
      this.$confirm('确认使用工具吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          // 用户点击确定执行的逻辑
          ProcessApi.useTool(this.tool.tool_id)
            .then((res) => {
              if (res.status) {
                this.$message({
                  message: '提交成功',
                  type: 'success',
                });
                this.$parent.getList();
              } else {
                this.$message({
                  message: res.message || '提交失败',
                  type: 'error',
                });
              }
            })
            .catch((err) => {
              this.$message({
                message: err.message || '提交失败',
                type: 'error',
              });
            });
        })
        .catch(() => {
          // 用户点击取消执行的逻辑
          this.$message({
            type: 'info',
            message: '已取消操作',
          });
        });
    },

    completedUseTool() {
      this.$confirm('确认使用完成吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          // 用户点击确定执行的逻辑
          ProcessApi.completedUseTool(this.tool.tool_id)
            .then((res) => {
              if (res.status) {
                this.$message({
                  message: '提交成功',
                  type: 'success',
                });
                this.$parent.getList();
              } else {
                this.$message({
                  message: res.message || '提交失败',
                  type: 'error',
                });
              }
            })
            .catch((err) => {
              this.$message({
                message: err.message || '提交失败',
                type: 'error',
              });
            });
        })
        .catch(() => {
          // 用户点击取消执行的逻辑
          this.$message({
            type: 'info',
            message: '已取消操作',
          });
        });
    },
    repairTool() {
      this.$confirm('确认维修工具吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          // 用户点击确定执行的逻辑
          ProcessApi.addMaintenanceFlow(this.tool.tool_id)
            .then((res) => {
              if (res.status) {
                this.$message({
                  message: '提交成功',
                  type: 'success',
                });
                this.$router.back();
              } else {
                this.$message({
                  message: res.message || '提交失败',
                  type: 'error',
                });
              }
            })
            .catch((err) => {
              this.$message({
                message: err.message || '提交失败',
                type: 'error',
              });
            });
        })
        .catch(() => {
          // 用户点击取消执行的逻辑
          this.$message({
            type: 'info',
            message: '已取消操作',
          });
        });
    },
  },
};
</script>

<style scoped>
.main {
  width: 90vw;
  box-shadow: 0px 2px 5px 0px rgba(84, 84, 84, 0.3);
  border-radius: 20px;
  background: white;
}

.main .status5 {
  border-bottom: 0.3rem solid #0daa90;
}

.main .status1,
.main .status9 {
  border-bottom: 0.3rem solid #e9e9e9;
}

.main .status2,
.main .status3 {
  border-bottom: 0.3rem solid #00b0ec;
}

.main .status4,
.main .status6,
.main .status7,
.main .status8 {
  border-bottom: 0.3rem solid #ff8da0;
}

.main .status5 {
  border-bottom: 0.3rem solid #feb132;
}

.head {
  padding: 2rem;
}

.head .title {
  font-size: 1.4rem;
}

.info {
  padding: 2rem;
  font-size: 1.2rem;
}

.info > div {
  margin-bottom: 1rem;
}

.info .process-info {
  margin: 0 auto;
  text-align: left;
  font-size: 1.2rem;
  padding: 1.2rem;
  background: #f6f8fb;
  border: 1px solid #ccc;
  border-radius: 10px;
}

.info .process-info > div {
  margin-bottom: 0.6rem;
}

.info .process-info .status {
  font-weight: bold;
  color: #0068b7;
}

.operate {
  border-top: 1px solid #e1e1e1;
  padding: 1rem 2rem;
  display: flex;
  justify-content: flex-end;
  min-height: 60px;
}
</style>
