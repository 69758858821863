import request from '@/utils/axios';

class ProcessApi {

  // 获取流程统计数据[inprocess, oper, todo, overdue]
  static async getFlowCountData (appId) {
    return await request.get('/tool_flow_info/GetFlowCountData', { appId })
  }

  // 获取进行中的流程
  static async getFlowInProcessData ({ type, status, keyword }) {
    return await request.get('/tool_flow_info/GetFlowInProcessData', { type, status, keyword })
  }

  // 获取已操作的流程
  static async getFlowOperData ({ type, status, keyword }) {
    return await request.get('/tool_flow_info/GetFlowOperData', { type, status, keyword })
  }

  // 获取待办的流程
  static async getFlowTodoData ({ type, status, keyword }) {
    return await request.get('/tool_flow_info/GetFlowTodoData', { type, status, keyword })
  }

  // 获取过期的流程
  static async getFlowOverdueData ({ type, status, keyword }) {
    return await request.get('/tool_flow_info/GetFlowOverdueData', { type, status, keyword })
  }

  // 获取损坏的部件
  static async getDamageData (flow_id) {
    return await request.get('/tool_flow_info/GetDamageData', { flow_id })
  }

  // 借调预约
  static async borrow ({ ToolId, IsBorrow, Start, End, UserId, UserName, Tel, Address, Remark }) {
    return await request.post('/tool_flow_info/Borrow',
      { ToolId, IsBorrow, Start, End, UserId, UserName, Tel, Address, Remark })
  }

  // 借调使用完成
  static async borrowCompleted (flowid) {
    return await request.post('/tool_flow_info/BorrowCompleted', { flowid })
  }

  // 预约取消
  static async reservationCancel (flowid) {
    return await request.post('/tool_flow_info/ReservationCancel', { flowid })
  }

  // 更新使用时间
  static async updateUseDate ({ flowid, start, end }) {
    return await request.post('/tool_flow_info/UpdateUseDate', { flowid, start, end })
  }

  // 填写工具校验
  static async addToolCheck ({ flow_id, mode, check_company, express_delivery_company, express_delivery_number, return_shipping_address, return_shipping_name, return_shipping_phone, express_delivery_img, tool_img
  }) {
    return await request.post('/tool_flow_info/AddToolCheck',
      {
        flow_id, mode, check_company, express_delivery_company, express_delivery_number, return_shipping_address, return_shipping_name, return_shipping_phone, express_delivery_img, tool_img
      })
  }

  // 工具校验完成
  static async toolCheckCompleted ({ flow_id, check_supplier, check_time, price, check_certificate }) {
    return await request.post('/tool_flow_info/ToolCheckCompleted',
      { flow_id, check_supplier, check_time, price, check_certificate })
  }

  // 填写维修信息
  static async addMaintenance ({ flowid, mode, damage_part, lose_part, maintenance_company, express_delivery_number, express_delivery_img, tool_img }) {
    return await request.post('/tool_flow_info/AddMaintenance',
      { flow_id: flowid, mode, damage_part, lose_part, maintenance_company, express_delivery_number, express_delivery_img, tool_img })
  }

  // 维修完成
  static async maintenanceCompleted ({ flow_id, mode, damage_part, lose_part, maintenance_company, maintenance_time, price, maintenance_file }) {
    return await request.post('/tool_flow_info/MaintenanceCompleted',
      { flow_id, mode, damage_part, lose_part, maintenance_company, maintenance_time, price, maintenance_file })
  }

  // 审核流程
  static async auditFlow ({ flowid, isPass, remark }) {
    return await request.post('/tool_flow_info/AuditFlow', { flowid, isPass, remark })
  }

  // 终止流程
  static async stopFlow ({ flowid, remark }) {
    return await request.post('/tool_flow_info/StopFlow', { flowid, remark })
  }


  // 借调/预约/校验流程回寄
  static async expressDelivery ({ flow_id, ExpressCompany, ExpressNumber, DeliveryImgs = [],
    ToolImgs = [] }) {
    return await request.post('/tool_flow_info/ExpressDelivery',
      { FlowId: flow_id, ExpressCompany, ExpressNumber, DeliveryImgs, ToolImgs })
  }


  // 物流收货
  static async takeOver ({ flowid, RecivedToolStatus, DamageParts, LoseParts, Remark, ToolImgs }) {
    return await request.post('/tool_flow_info/TakeOver',
      { FlowId: flowid, RecivedToolStatus, DamageParts, LoseParts, Remark, ToolImgs })
  }


  // 工具缺损
  static async toolDamage ({ flowid, RecivedToolStatus, DamageParts, LoseParts, Remark }) {
    return await request.post('/tool_flow_info/ToolDamage',
      { FlowId: flowid, RecivedToolStatus, DamageParts, LoseParts, Remark })
  }

  // 获取借调单信息
  static async getBorrowInfoByFlowId (flowid) {
    return await request.get('/tool_flow_borrow_info/GetByFlowId', { flowid })
  }

  // 获取物流信息
  static async getExpressDeliveryInfoByFlowId (flowid) {
    return await request.get('/tool_flow_express_delivery_info/GetByFlowId', { flowid })
  }

  // 获取收货信息
  static async getTakeOverInfoByFlowId (flowid) {
    return await request.get('/tool_flow_take_over_info/GetByFlowId', { flowid })
  }


  // 获取流程详情
  static async getFlowDetail (flowid) {
    return await request.get('/tool_flow_info/GetFlowDetail', { flowid })
  }

  // 新建校验流程
  static async addToolCheckFlow (toolId) {
    return await request.post('/tool_flow_info/AddToolCheckFlow', { toolId })
  }

  // 新建维修流程
  static async addMaintenanceFlow (toolId) {
    return await request.post('/tool_flow_info/AddMaintenanceFlow', { toolId })
  }

  // 工具丢失
  static async loseTool (toolid) {
    return await request.post('/tool_flow_info/ToolLost', { toolid })
  }

  // 工具使用
  static async useTool (toolid) {
    return await request.post('/tool_flow_info/ToolUseStart', { toolid })
  }

  // 工具使用完成
  static async completedUseTool (toolid) {
    return await request.post('/tool_flow_info/ToolUseCompleted', { toolid })
  }
  // 工具流转记录
  static async getPageDataEx (toolId, pageSize, page, sort, asc) {
    return await request.get('/tool_inventory_info/GetPageDataEx', { toolId, pageSize, page, sort, asc })
  }

  // 获取维修工具部件
  static async getFlowDamageData (flow_id) {
    return await request.get('/tool_flow_info/GetDamageData', { flow_id })
  }

}

export default ProcessApi