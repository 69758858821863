<template>
  <div class="top">
    <div class="login flex-start-center">
      <div>
        <img class="avatar" src="@/assets/images/home/avatar_icon.png" alt="" />
      </div>
      <div class="info" v-if="name">
        <p class="name">Hi! {{ name }}！</p>
        <p class="position">职务：{{ position }}</p>
      </div>
      <div class="info" v-if="!name">
        <p class="name">未登录</p>
      </div>
    </div>
  </div>

  <div class="tool">
    <div class="title">工具</div>
    <div class="flex-center-center">
      <div class="pr" @click="navigateTo('tool', 'first')">
        <img src="@/assets/images/home/transfer.png" alt="" />
        <div>借调中工具</div>
        <span class="badge" v-if="toolCount[0] > 0">{{ toolCount[0] }}</span>
      </div>
      <div class="pr" @click="navigateTo('tool', 'second')">
        <img src="@/assets/images/home/verify.png" alt="" />
        <div>校验中工具</div>
        <span class="badge" v-if="toolCount[1] > 0">{{ toolCount[1] }}</span>
      </div>
      <div class="pr" @click="navigateTo('tool', 'third')">
        <img src="@/assets/images/home/damage.png" alt="" />
        <div>损坏工具</div>
        <span class="badge" v-if="toolCount[2] > 0">{{ toolCount[2] }}</span>
      </div>
      <div class="pr" @click="navigateTo('tool', 'fourth')">
        <img src="@/assets/images/home/my.png" alt="" />
        <div>我的工具</div>
        <span class="badge" v-if="toolCount[3] > 0">{{ toolCount[3] }}</span>
      </div>
    </div>
  </div>

  <div class="process">
    <div class="title">流程</div>
    <div class="flex-center-center flex-wrap">
      <div class="flex-start-center flex_dir_row pr" @click="navigateTo('process', 'first')">
        <img src="@/assets/images/home/progress.png" alt="" />
        <p>进行中的流程</p>
        <span class="badge" v-if="processCount[0] > 0">{{ processCount[0] }}</span>
      </div>
      <div class="flex-start-center flex_dir_row pr" @click="navigateTo('process', 'second')">
        <img src="@/assets/images/home/opreate.png" alt="" />
        <p>我操作的流程</p>
        <span class="badge" v-if="processCount[1] > 0">{{ processCount[1] }}</span>
      </div>
      <div class="flex-start-center flex_dir_row pr" @click="navigateTo('process', 'third')">
        <img src="@/assets/images/home/todo.png" alt="" />
        <p>待办事项</p>
        <span class="badge" v-if="processCount[2] > 0">{{ processCount[2] }}</span>
      </div>
      <div class="flex-start-center flex_dir_row pr" @click="navigateTo('process', 'fourth')">
        <img src="@/assets/images/home/expire.png" alt="" />
        <p>已过期流程</p>
        <span class="badge" v-if="processCount[3] > 0">{{ processCount[3] }}</span>
      </div>
    </div>
  </div>
  <tool-footer activeMenu="home"></tool-footer>
  <el-dialog v-model="showLogin" width="350" :show-close="false" top="35vh">
    <template #header="{ close, titleId, titleClass }">
      <div class="dialog-header">
        <p class="title" :id="titleId" :class="titleClass">登录</p>
        <p class="close" @click="close">取消</p>
      </div>
    </template>

    <div class="form">
      <el-form label-position="top" :model="form" ref="form" @submit.native.prevent>
        <el-form-item label="" prop="appid">
          <el-input v-model="form.appid" placeholder="appid" @keyup.enter="login" />
        </el-form-item>
      </el-form>
    </div>
    <div class="flex-center-center">
      <el-button class="submit-btn" @click="login" size="large"> 确认提交 </el-button>
    </div>
  </el-dialog>
  <!-- <div class="login-icon" @click="showLoginDialog">
    <p>登录</p>
  </div> -->
</template>

<script>
import UserApi from '@/utils/api/user';
import ToolApi from '@/utils/api/tool';
import ProcessApi from '@/utils/api/process';
import { generateAuthUrl, transferDict } from '@/utils/utils';
import { useDictStore } from '@/stores/useDictStore';
import { useUserStore } from '@/stores/useUserStore';
export default {
  name: 'Home',
  data() {
    return {
      name: '',
      position: '',
      allDict: [],
      toolCount: [],
      processCount: [],
      showLogin: false,
      form: {
        appid: '',
      },
    };
  },

  created() {
    this.init();
  },
  methods: {
    async init() {
      const dictStore = useDictStore();
      const userStore = useUserStore();
      const user = userStore.user;
      if (!user) {
        const code = this.$route.query?.code;
        if (code) {
          console.log('code', code);
          await this.loginByCode(code);
          return;
        } else {
          window.location.href = await UserApi.getWexinBackUrl();
          return;
        }
      } else {
        this.allDict = await dictStore.getDict();
        this.name = user.name;
        this.position = transferDict(this.allDict, 'tool_user_position', user?.positon);
        this.toolCount = await ToolApi.getToolCountData();
        this.processCount = (await ProcessApi.getFlowCountData()).data;
      }
    },
    // async login() {
    //   const userStore = useUserStore();
    //   userStore
    //     .login(this.form.appid)
    //     .then(async (res) => {
    //       if (res.status) {
    //         this.$message({
    //           message: '登录成功',
    //           type: 'success',
    //         });
    //         this.showLogin = false;
    //         await this.init();
    //       } else {
    //         this.$message({
    //           message: res.message || '服务器异常',
    //           type: 'error',
    //         });
    //         return;
    //       }
    //     })
    //     .catch((err) => {
    //       this.$message({
    //         message: err?.response?.data?.message || '服务器异常',
    //         type: 'error',
    //       });
    //     });
    // },

    async loginByCode(code) {
      const userStore = useUserStore();
      userStore
        .loginByCode(code)
        .then(async (res) => {
          if (res.status) {
            this.$message({
              message: '登录成功',
              type: 'success',
            });
            await this.init();
          } else {
            this.$message({
              message: res.message || '服务器异常',
              type: 'error',
            });
            return;
          }
        })
        .catch((err) => {
          this.$message({
            message: err?.response?.data?.message || '服务器异常',
            type: 'error',
          });
        });
    },

    navigateTo(route, activeName) {
      console.log(route, activeName);
      this.$router.push({ name: route, query: { activeName } });
    },
    showLoginDialog() {
      this.showLogin = true;
    },
  },
};
</script>

<style scoped>
.login-icon {
  position: fixed;
  right: 30px;
  bottom: 100px;
  width: 60px;
  height: 60px;
  background: #0068b7;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-icon p {
  color: white;
  cursor: pointer;
  font-size: 14px;
}

.dialog-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 16px;
}

.dialog-header .title {
  font-size: 18px;
  color: #343434;
}

.dialog-header .close {
  cursor: pointer;
  font-size: 16px;
  color: #343434;
}

.top {
  width: 100vw;
  height: 30vh;
  background: url('@/assets/top_bg.png') no-repeat top;
  background-size: cover;
  color: white;
}

.login {
  padding-left: 10vw;
  padding-top: 8vh;
}

.login .info .name {
  font-size: 1.8rem;
  text-align: left;
  font-weight: bold;
  margin-bottom: 1rem;
}

.login .info .position {
  font-size: 1.4rem;
  text-align: left;
}

.avatar {
  width: 10rem;
}

.tool {
  width: 80vw;
  margin: 0 auto;
  margin-top: -8vh;
}

.tool .title {
  color: white;
  font-size: 1.8rem;
  text-align: left;
  margin-bottom: 2rem;
}

.tool .flex-center-center {
  background: white;
  padding: 2rem 1rem;
  border-radius: 15px;
  box-shadow: 0px 2px 5px 0px rgba(84, 84, 84, 0.3);
  font-size: 1.2rem;
}

.tool img {
  width: 100%;
}

.tool .badge {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  position: absolute;
  top: 0;
  right: 0;
  background-color: #ff3e3e;
  color: white;
  border-radius: 50%;
}

.process {
  width: 80vw;
  margin: 0 auto;
  margin-top: 2vh;
}

.process .title {
  font-size: 1.8rem;
  text-align: left;
  margin-bottom: 2rem;
}

.process .flex-center-center {
  border-radius: 15px;
  box-shadow: 0px 2px 5px 0px rgba(84, 84, 84, 0.3);
  padding: 1rem;
}

.process .flex-center-center div {
  width: 50%;
  padding: 2rem 1rem;
  height: 7rem;
}

.process .flex-center-center div:nth-child(odd) {
  border-right: 1px solid #ccc;
}

.process .flex-center-center div:nth-child(1),
.process .flex-center-center div:nth-child(2) {
  border-bottom: 1px solid #ccc;
}

.process .flex-center-center img {
  width: 2.5rem;
}

.process .flex-center-center p {
  font-size: 1.2rem;
  margin: 0 0.8rem;
  white-space: nowrap;
}

.process .badge {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  position: absolute;
  top: 10px;
  left: 36px;
  background-color: #ff3e3e;
  color: white;
  border-radius: 50%;
}
</style>
