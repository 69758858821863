import request from '@/utils/axios';
import { FRONT_URL } from '../config';

class UserApi {
  // 登录
  static async login(appId) {
    const res = await request.get('/tool_users/login', { appId });
    return res;
  }

  // 获取当前用户信息
  static async getUserInfo() {
    return await request.get('/tool_users/getCurrentUser');
  }

  // 获取派工员下面的工程师
  static async getToolUserByDispatcher(dispatcher_userId) {
    return await request.get('/tool_users/getToolUserByDispatcher', { dispatcher_userId });
  }

  // 获取字典信息
  static async getToolDict(dicNos = []) {
    let query = '';
    for (let i = 0; i < dicNos.length; i++) {
      if (i === 0) query += `?dicNos=${dicNos[i]}`;
      else query += `&dicNos=${dicNos[i]}`;
    }
    return await request.get(`/tool_users/GetAllToolDict${query}`);
  }

  // 根据id获取用户信息
  static async getUseInfoById(userId) {
    return await request.get('/tool_users/Get', { userId });
  }
  // 根据id获取用户地址信息
  static async getUserAddressById(userId) {
    return await request.get('/tool_users/GetUserAddress', { userId });
  }

  // 获取微信授权地址
  static async getWxUserByCode(code) {
    return await request.get('/tool_users/GetWxUserByCode', { code });
  }

  // 获取微信授权地址
  static async getWexinBackUrl() {
    return await request.get('/tool_users/GetWexinBackUrl', { url: FRONT_URL });
  }
}

export default UserApi;
