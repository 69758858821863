const FRONT_URL = process.env.VUE_APP_FRONTEND_URL;
const BACKEND_URL = process.env.VUE_APP_BACKEND_URL;

const UPLOAD_PATH = process.env.VUE_APP_UPLOAD_PATH;

module.exports = {
  FRONT_URL,
  BACKEND_URL,
  UPLOAD_PATH,
};
