<template>
  <div class="detail-header">
    <div class="flex-between-center">
      <div class="return flex-start-center">
        <img src="@/assets/images/return_icon.png" alt="" class="cp" @click="back" />
      </div>
      <p class="title">工具详情</p>
      <div class="flex-end-center search">
        <!-- <p class="searchText">查看流程</p> -->
      </div>
    </div>
  </div>
  <div class="detail-body">
    <div class="flex-between-center detail-body-head">
      <div class="title">
        {{ tool.name }}
      </div>
      <div class="tags flex-start-center">
        <tag :name="borrowStatus"></tag>
        <tag :name="reservationStatus"></tag>
      </div>
    </div>
    <div class="detail-body-info">
      <el-collapse accordion>
        <el-collapse-item>
          <template v-slot:title>
            <div class="text">工具归属者：{{ tool.owner_name }}</div>
          </template>
          <div class="text">当前使用者：{{ tool.user_name }}</div>
          <div class="text" v-if="tool?.use_start_time && tool?.use_end_time">
            使用时间：{{ new Date(tool?.use_start_time).toLocaleDateString() }}～{{ new Date(tool?.use_end_time).toLocaleDateString() }}
          </div>
          <div class="text">预约借调者：{{ tool.second_user_name }}</div>
          <!-- <div class="text"> 预约时间：2024.01.15～2024.02.31</div> -->
        </el-collapse-item>
      </el-collapse>
    </div>
    <div class="detail-body-tool-info">
      <div class="main">
        <div class="text">资产编号：{{ tool?.asset_number }}</div>
        <div class="text">采购时间：{{ new Date(tool?.purchase_date).toLocaleDateString() }}</div>
        <div class="text">工具负责人：{{ tool?.owner_name }}</div>
        <div class="text">所属大区：{{ tool?.region_name }}</div>
        <div class="text">所属小区：{{ tool?.small_region_name }}</div>
        <div class="text">工具大类：{{ tool?.first_cat_name }}</div>
        <div class="text">工具小类：{{ tool?.second_cat_name }}</div>
        <div class="text">
          适用机型：<span class="model"> {{ tool?.applicable_models }}</span>
        </div>
      </div>
      <div class="tips">备注：{{ tool?.remark }}</div>
    </div>
  </div>
  <div class="detail-record">
    <el-tabs v-model="activeName" @tab-click="handleClick" :stretch="true">
      <el-tab-pane label="工具部件" name="first">
        <div class="collapse">
          <el-collapse accordion v-for="part in toolParts" :key="part.part_id">
            <el-collapse-item>
              <template v-slot:title>
                <div class="title">
                  {{ part.part_name }}
                </div>
              </template>
              <div class="text">订购号：{{ part.part_number }}</div>
              <div class="text">包含/选配：无</div>
            </el-collapse-item>
          </el-collapse>
        </div>
      </el-tab-pane>
      <el-tab-pane label="维修记录" name="second">
        <div
          class="flex-between-center link"
          v-for="record in repairRecords"
          :key="record.id"
          @click="navigateToFlow('processDetail', record.flow_id)"
        >
          <div class="flex-start-center">
            <div><img class="icon" src="@/assets/images/repair-icon.png" alt="" /></div>
            <div class="title">{{ new Date(record.create_time).toLocaleDateString() }}</div>
          </div>
          <div><img class="right-link" src="@/assets/images/right-link.png" alt="" /></div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="校验记录" name="third">
        <div
          class="flex-between-center link"
          v-for="record in checkRecords"
          :key="record.id"
          @click="navigateToFlow('processDetail', record.flow_id)"
        >
          <div class="flex-start-center">
            <div><img class="icon" src="@/assets/images/check-icon.png" alt="" /></div>
            <div class="title">{{ new Date(record.create_time).toLocaleDateString() }}</div>
          </div>
          <div><img class="right-link" src="@/assets/images/right-link.png" alt="" /></div>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
  <div class="detail-opreate">
    <div class="flex-between-center">
      <div class="btn verify" @click="verfiyTool" v-if="this.tool.tool_user == user.user_id && this.tool.is_check === 1">校验</div>
      <div class="btn complete" @click="completedUseTool" v-if="this.tool.tool_user == user.user_id && this.tool.tool_status == 3">
        使用完成
      </div>
      <div
        class="btn complete"
        @click="useTool"
        v-if="this.tool.tool_user == user.user_id && this.tool.owner == user.user_id && this.tool.tool_status == 1"
      >
        使用
      </div>
    </div>
    <div class="flex-between-center">
      <div class="btn bbcw" @click="repairTool" v-if="this.tool.tool_user == user.user_id">维修</div>
      <div class="btn bbcw" @click="navigateToTool('stocktaking', this.tool.tool_id)">流转记录</div>
      <div class="btn bbcw" @click="loseTool" v-if="this.tool.tool_user == user.user_id">丢失</div>
    </div>
  </div>
</template>

<script>
import ToolApi from '@/utils/api/tool';
import ProcessApi from '@/utils/api/process';
import { transferDict } from '@/utils/utils';
import { useDictStore } from '@/stores/useDictStore';
import { useUserStore } from '@/stores/useUserStore';

export default {
  data() {
    return {
      activeName: 'first',
      tool: {},
      borrowStatus: '',
      reservationStatus: '',
      toolParts: [],
      repairRecords: [],
      checkRecords: [],
    };
  },
  created() {
    this.init();
  },
  mounted() {},
  methods: {
    async init() {
      const userStore = useUserStore();
      this.user = userStore.user;
      const tool_id = this.$route.query?.tool_id;
      if (tool_id) {
        const loadingInstance = this.$loading({ text: '加载中...' });
        const dictStore = useDictStore();
        this.dicts = await dictStore.getDict();
        const res = await ToolApi.getToolDetail(tool_id);
        this.tool = res.data?.[0];
        this.borrowStatus = transferDict(this.dicts, 'tool_status', this.tool.tool_status);
        this.reservationStatus = transferDict(this.dicts, 'tool_reservation_status', this.tool.reservation_status);
        this.toolParts = await ToolApi.getPartList(tool_id);
        this.repairRecords = await ToolApi.getToolMaintenances(tool_id);
        this.checkRecords = await ToolApi.getToolChecks(tool_id);
        loadingInstance.close();
      } else {
        this.$message({
          message: '参数缺失',
          type: 'error',
        });
        this.$router.back();
      }
    },
    back() {
      this.$router.back();
    },
    navigateTo(routerName) {
      this.$router.push({ name: routerName });
    },
    navigateToFlow(routerName, flow_id) {
      this.$router.push({ name: routerName, query: { flow_id } });
    },
    navigateToTool(routerName, tool_id) {
      this.$router.push({ name: routerName, query: { tool_id } });
    },
    useTool() {
      this.$confirm('确认使用工具吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          // 用户点击确定执行的逻辑
          ProcessApi.useTool(this.tool.tool_id)
            .then((res) => {
              if (res.status) {
                this.$message({
                  message: '提交成功',
                  type: 'success',
                });
                this.init();
              } else {
                this.$message({
                  message: res.message || '提交失败',
                  type: 'error',
                });
              }
            })
            .catch((err) => {
              this.$message({
                message: err.message || '提交失败',
                type: 'error',
              });
            });
        })
        .catch(() => {
          // 用户点击取消执行的逻辑
          this.$message({
            type: 'info',
            message: '已取消操作',
          });
        });
    },

    completedUseTool() {
      this.$confirm('确认使用完成吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          // 用户点击确定执行的逻辑
          ProcessApi.completedUseTool(this.tool.tool_id)
            .then((res) => {
              if (res.status) {
                this.$message({
                  message: '提交成功',
                  type: 'success',
                });
                this.init();
              } else {
                this.$message({
                  message: res.message || '提交失败',
                  type: 'error',
                });
              }
            })
            .catch((err) => {
              this.$message({
                message: err.message || '提交失败',
                type: 'error',
              });
            });
        })
        .catch(() => {
          // 用户点击取消执行的逻辑
          this.$message({
            type: 'info',
            message: '已取消操作',
          });
        });
    },
    verfiyTool() {
      this.$confirm('确认校验工具吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          // 用户点击确定执行的逻辑
          ProcessApi.addToolCheckFlow(this.tool.tool_id)
            .then((res) => {
              if (res.status) {
                this.$message({
                  message: '提交成功',
                  type: 'success',
                });
                this.$router.back();
              } else {
                this.$message({
                  message: res.message || '提交失败',
                  type: 'error',
                });
              }
            })
            .catch((err) => {
              this.$message({
                message: err.message || '提交失败',
                type: 'error',
              });
            });
        })
        .catch(() => {
          // 用户点击取消执行的逻辑
          this.$message({
            type: 'info',
            message: '已取消操作',
          });
        });
    },
    repairTool() {
      this.$confirm('确认维修工具吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          // 用户点击确定执行的逻辑
          ProcessApi.addMaintenanceFlow(this.tool.tool_id)
            .then((res) => {
              if (res.status) {
                this.$message({
                  message: '提交成功',
                  type: 'success',
                });
                this.$router.back();
              } else {
                this.$message({
                  message: res.message || '提交失败',
                  type: 'error',
                });
              }
            })
            .catch((err) => {
              this.$message({
                message: err.message || '提交失败',
                type: 'error',
              });
            });
        })
        .catch(() => {
          // 用户点击取消执行的逻辑
          this.$message({
            type: 'info',
            message: '已取消操作',
          });
        });
    },
    loseTool() {
      this.$confirm('确认工具丢失吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          // 用户点击确定执行的逻辑
          ProcessApi.loseTool(this.tool.tool_id)
            .then((res) => {
              if (res.status) {
                this.$message({
                  message: '提交成功',
                  type: 'success',
                });
                this.$router.back();
              } else {
                this.$message({
                  message: res.message || '提交失败',
                  type: 'error',
                });
              }
            })
            .catch((err) => {
              this.$message({
                message: err.message || '提交失败',
                type: 'error',
              });
            });
        })
        .catch(() => {
          // 用户点击取消执行的逻辑
          this.$message({
            type: 'info',
            message: '已取消操作',
          });
        });
    },
  },
};
</script>

<style scoped>
.detail-header {
  width: 100vw;
  height: 10vh;
  background: url('@/assets/top_bg.png') no-repeat top;
  background-size: cover;
  color: white;
  display: flex;
  padding: 0 2rem;
}

.detail-header .flex-between-center {
  width: 100%;
}

.detail-header .flex-between-center div {
  width: 30%;
}

.detail-header .flex-between-center .return {
  text-align: left;
}

.detail-header .flex-between-center .return img {
  width: 1rem;
}

.detail-header .flex-between-center .search img {
  width: 2rem;
}

.detail-header .flex-between-center .title {
  font-size: 1.8rem;
}

.detail-header .flex-between-center .search p {
  font-size: 1.4rem;
  margin: 0 0.6rem;
}

.detail-body-head {
  padding: 2rem;
  border-bottom: 1px solid #e0e1e1;
}

.detail-body-head .title {
  font-size: 1.4rem;
}

.detail-body-info .text {
  font-size: 1.2rem;
  text-align: left;
  padding: 0.4rem 2rem;
}

.detail-body-tool-info {
  width: 90vw;
  border-radius: 20px;
  box-shadow: 0px 2px 5px 0px rgba(84, 84, 84, 0.3);
  background: white;
  margin: 2rem auto;
  padding: 2rem;
}

.detail-body-tool-info > div {
  margin-bottom: 2rem;
}

.detail-body-tool-info .main > div {
  font-size: 1.2rem;
  text-align: left;
  padding: 0.3rem 0;
}

.detail-body-tool-info .text .model {
  color: #0068b7;
  font-weight: bold;
}

.detail-body-tool-info .tips {
  text-align: left;
  font-size: 1.2rem;
  padding: 1.5rem;
  background: #f6f8fb;
  border: 1px solid #ccc;
  border-radius: 10px;
}

.detail-record {
  background: white;
  margin-bottom: 5rem;
}

.detail-record .collapse {
  width: 90vw;
  margin: 1rem auto;
}

.detail-record .collapse > div {
  margin: 1.4rem 0;
  box-shadow: 0px 2px 5px 0px rgba(84, 84, 84, 0.3);
}

.detail-record .link {
  width: 90vw;
  background: white;
  margin: 1rem auto;
}

.detail-record .link {
  padding: 1.2rem 1.5rem;
  box-shadow: 0px 2px 5px 0px rgba(84, 84, 84, 0.3);
}

.detail-record .collapse .title {
  font-size: 1.4rem;
  text-align: left;
  padding: 0.2rem 2rem;
  font-weight: bold;
}

.detail-record .collapse .text {
  font-size: 1.2rem;
  text-align: left;
  padding: 0.2rem 2rem;
}

.detail-record .link .title {
  font-size: 1.4rem;
  text-align: left;
  font-weight: bold;
}

.detail-record .link .right-link {
  width: 10px;
}

.detail-record .link .icon {
  width: 30px;
  margin-right: 10px;
}

.detail-record .link .text {
  font-size: 1.2rem;
  text-align: left;
  padding: 0.2rem 2rem;
}

:deep(.detail-record .el-tabs__header) {
  margin: 0 !important;
}

:deep(.detail-record .el-tabs__item) {
  font-size: 1.4rem;
  font-weight: bold;
  padding: 2.4rem 1rem !important;
}

:deep(.detail-record .el-tabs__content) {
  background: #f6f8fb;
}

.detail-opreate {
  background: white;
  padding: 2rem 5vw;
}

.detail-opreate > div {
  padding: 2rem 0;
}

.detail-opreate > div:first-child {
  border-bottom: 1px solid #e0e1e1;
}

.detail-opreate .btn {
  width: 90%;
  font-size: 1.4rem;
  margin: 0 0.5rem;
  border-radius: 5px;
  padding: 1rem 0;
  cursor: pointer;
}

.detail-opreate .btn.verify {
  border: 1px solid #343434;
  color: #343434;
}

.detail-opreate .btn.complete {
  background-color: #0068b7;
  color: white;
}

.detail-opreate .btn.bbcw {
  background-color: #343434;
  color: white;
}

.detail-opreate .btn.brcw {
  background-color: #cd4a5f;
  color: white;
}
</style>
